import { Box } from '@material-ui/core'
import { Typography } from '@mui/material'

export const FixedOccupancyPlanOverview = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Typography>TODO</Typography>
    </Box>
  )
}
